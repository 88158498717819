import SDK3DVerse_ExtensionInterface from 'ExtensionInterface';
import * as THREE from 'three';

//------------------------------------------------------------------------------
export default class SDK3DVerse_ThreeJS extends SDK3DVerse_ExtensionInterface
{
    //--------------------------------------------------------------------------
    constructor(sdk)
    {
        super(sdk, "ThreeJS");
        this.initializeThreeJS()
    }

    //--------------------------------------------------------------------------
    async onInit()
    {
        console.log(`SDK3DVerse_ThreeJS onInit`);

        this.initializeThreeJS();

        this.sdk.threeJS = {
            renderer    : this.renderer,
            scene       : this.scene,
            ext         : this,
            THREE       : THREE
        };

        this.onViewportsUpdated(this.sdk.engineAPI.cameraAPI.getActiveViewports());

        this.sdk.notifier.on('onViewportsUpdated', this.onViewportsUpdated);
        this.sdk.notifier.on('onFramePreRender', this.onFramePreRender);
        this.sdk.notifier.on('onFramePostRender', this.onFramePostRender);
        this.sdk.notifier.on('onCanvasResized', this.onCanvasResized);
        this.sdk.notifier.on('onDisplayReady', this.updateDisplay);
    }

    //--------------------------------------------------------------------------
    initializeThreeJS()
    {
        this.canvas                 = this.sdk.streamerConfig.display.canvas;
        this.context                = this.canvas.getContext("2d");

        const virtualCanvas         = document.createElementNS('http://www.w3.org/1999/xhtml', 'canvas');

        const contextAttributes     = {
            alpha: true,
            antialias : true
        };

        this.glContext              = virtualCanvas.getContext('webgl', contextAttributes);

        this.renderer               = new THREE.WebGLRenderer({ context: this.glContext, canvas : virtualCanvas });
        this.renderer.autoClear     = false;
        this.overlayCanvas          = this.renderer.domElement;

        this.renderer.setPixelRatio(1);
        this.renderer.setClearColor(0xffffff, 0);
        this.onCanvasResized(this.canvas.clientWidth, this.canvas.clientHeight);

        this.scene                  = new THREE.Scene();
        this.viewports              = [];

        this.debugLines             = [];
        this.debugLineColors        = [];
        this.debugLineScene         = new THREE.Scene();

        this.sprites                = new Set();
        this.cameraPosition         = new THREE.Vector3();
        this.tempVec                = new THREE.Vector3();

        const material = new THREE.LineBasicMaterial({ vertexColors: THREE.VertexColors } );

        this.debugLinesGeometry = new THREE.BufferGeometry();
        this.debugLinesGeometry.addAttribute( 'position', new THREE.Float32BufferAttribute( [], 3 ) );
        this.debugLinesGeometry.addAttribute( 'color', new THREE.Float32BufferAttribute( [], 3 ) );

        this.lineSegmentInstance = new THREE.LineSegments( this.debugLinesGeometry, material );
        this.lineSegmentInstance.frustumCulled = false;
        this.debugLineScene.add(this.lineSegmentInstance);
    }

    //--------------------------------------------------------------------------
    dispose()
    {
        this.renderer.dispose();
        this.renderer.forceContextLoss();
        this.renderer.context = null;
        this.renderer.domElement = null;

        this.sdk.notifier.off('onViewportsUpdated', this.onViewportsUpdated);
        this.sdk.notifier.off('onFramePreRender', this.onFramePreRender);
        this.sdk.notifier.off('onFramePostRender', this.onFramePostRender);
        this.sdk.notifier.off('onCanvasResized', this.onCanvasResized);
        this.sdk.notifier.off('onDisplayReady', this.updateDisplay);
    }

    //--------------------------------------------------------------------------
    updateDisplay = () =>
    {
        this.canvas     = this.sdk.streamerConfig.display.canvas;
        this.context    = this.canvas.getContext("2d");
        this.onCanvasResized(this.canvas.clientWidth, this.canvas.clientHeight);
    }

    //--------------------------------------------------------------------------
    onViewportsUpdated = (viewports) =>
    {
        this.viewports = viewports;

        this.viewports.forEach((viewport) =>
        {
            viewport.threeJScamera  = viewport.getProjectionType() === 0
                                    ? new THREE.PerspectiveCamera()
                                    : new THREE.OrthographicCamera();
            viewport.threeJScamera.matrixAutoUpdate = false;
        });
    }

    //--------------------------------------------------------------------------
    onFramePreRender = () =>
    {
        this.clearDebugLines();

        for(let viewport of this.viewports)
        {
            if(viewport.disableThreeJS)
            {
                continue;
            }

            const camera = viewport.threeJScamera;

            camera.matrix.fromArray(viewport.worldMatrix);
            camera.updateMatrixWorld(true);
            camera.projectionMatrix.fromArray(viewport.projectionMatrix);
        }
    }

    //--------------------------------------------------------------------------
    onFramePostRender = () =>
    {
        const renderSize = this.renderer.getSize();
        if(renderSize.width == 0 || renderSize.height == 0)
        {
            return;
        }

        this.renderer.clear();
        this.updateDebugLines();

        for(let viewport of this.viewports)
        {
            if(viewport.disableThreeJS)
            {
                continue;
            }

            const camera        = viewport.threeJScamera;
            const areaSize      = viewport.getAreaSize();
            const offset        = viewport.getOffset();

            const cameraPos     = this.cameraPosition.setFromMatrixPosition(camera.matrixWorld);

            for(const sprite of this.sprites)
            {
                let v = this.tempVec.copy(sprite.position).sub(cameraPos).lengthSq() * 0.015;
                sprite.material.opacity = Math.min(1.0, v);
            }

            this.renderer.setViewport(offset[0], offset[1], areaSize[0], areaSize[1]);
            this.renderer.setScissor(offset[0], offset[1], areaSize[0], areaSize[1]);
            this.renderer.setScissorTest(true);
            this.renderer.clear();
            this.renderer.setScissorTest(false);
            this.renderer.render(this.debugLineScene, camera);

            if(!viewport.disableThreeJSMeshes)
            {
                this.renderer.render(this.scene, camera);
            }
        }

        this.context.drawImage(this.overlayCanvas, 0, 0);
    }

    //--------------------------------------------------------------------------
    addSprite(sprite)
    {
        this.sprites.add(sprite);
    }

    //--------------------------------------------------------------------------
    removeSprite(sprite)
    {
        this.sprites.delete(sprite);
    }

    //--------------------------------------------------------------------------
    onCanvasResized = (width, height) =>
    {
        this.renderer.setSize(width, height);
    }

    //--------------------------------------------------------------------------
    clearDebugLines()
    {
        this.debugLines.length = 0;
        this.debugLineColors.length = 0;
    }

    //--------------------------------------------------------------------------
    addLine(a, b, color)
    {
        this.addLine2(a, b, color, color);
    }

    //--------------------------------------------------------------------------
    addLine2(a, b, color, color2)
    {
        if(Array.isArray(a))
        {
            this.debugLines.push(
                a[0], a[1], a[2]
            );
        }
        else
        {
            this.debugLines.push(
                a.x, a.y, a.z
            );
        }

        if(Array.isArray(b))
        {
            this.debugLines.push(
                b[0], b[1], b[2]
            );
        }
        else
        {
            this.debugLines.push(
                b.x, b.y, b.z
            );
        }

        this.debugLineColors.push(
            color.r, color.g, color.b,
            color2.r, color2.g, color2.b
        );
    }

    //--------------------------------------------------------------------------
    addPlaneOrthonormalBase(plane)
    {
        const xAxis     = new THREE.Vector3().copy(plane.right);
        const yAxis     = new THREE.Vector3().copy(plane.up);
        const zAxis     = new THREE.Vector3().copy(plane.normal);

        xAxis.add(plane.position);
        yAxis.add(plane.position);
        zAxis.add(plane.position);

        this.addLine(plane.position, xAxis, new THREE.Color("red"));
        this.addLine(plane.position, yAxis, new THREE.Color("green"));
        this.addLine(plane.position, zAxis, new THREE.Color("blue"));
    }

    //--------------------------------------------------------------------------
    updateDebugLines()
    {
        this.debugLinesGeometry.removeAttribute('position');
        this.debugLinesGeometry.removeAttribute('color');

        this.debugLinesGeometry.addAttribute('position', new THREE.Float32BufferAttribute(this.debugLines, 3));
        this.debugLinesGeometry.addAttribute('color', new THREE.Float32BufferAttribute(this.debugLineColors, 3));
    }
}
